import React from 'react';
import { withRouter } from 'react-router-dom';

import request from '../../helpers/request';

import { Base64 } from 'js-base64';
import copy from 'copy-to-clipboard';

import gdprConsentInvitationConfig from './config/gdprConsentInvitationConfig';

import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopy from '@material-ui/icons/FileCopy';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import MaterialButton from '@material-ui/core/Button';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import withStyles from '@material-ui/core/styles/withStyles';
import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';

import { showInfoMessage } from '../../helpers/notification';

const styles = {
  ...userProfileStyles,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
  },
};

class NewCandidateInvitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      profession: '',
      phone: '',
      address: '',
      email: '',
      gdprConsentInvitationNumber: null,
      urlBase: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);

    this.createGdprLinks = this.createGdprLinks.bind(this);
    this.createActionLink = this.createActionLink.bind(this);
  }
  createActionLink(gdprConsentInvitationNumber, action) {
    if (!gdprConsentInvitationNumber) {
      return null;
    }

    const data = {
      name: this.state.name,
      profession: this.state.profession,
      phone: this.state.phone,
      address: this.state.address,
      email: this.state.email,
      number: gdprConsentInvitationNumber,
      action
    };

    const code = Base64.encodeURI(JSON.stringify(data));

    return `${this.state.urlBase}Privola/Zahtjev/${code}`;
  }
  createGdprLinks() {
    return {
      acceptLink: this.createActionLink(this.state.gdprConsentInvitationNumber, 'accept'),
      rejectLink: this.createActionLink(this.state.gdprConsentInvitationNumber, 'reject')
    };
  }
  handleChange(e) {
    const propName = e.target.name;
    const value = e.target.value;

    let newState = {};
    newState[propName] = value;

    this.setState(newState);
  }
  create() {
    request.post(
      `${gdprConsentInvitationConfig.apiUrl}Create`,
      {},
      response => {
        this.setState({
          gdprConsentInvitationNumber: response.gdprConsentInvitation.number,
          urlBase: response.urlBase});
      }
    );
  }
  render() {
    const { classes } = this.props;

    const title = 'Novi poziv za GDPR privolu';

    const { acceptLink } = this.createGdprLinks();

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <InsertDriveFile />
              </CardIcon>
              <h2 className={classes.cardIconTitle}>
                {title}
              </h2>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    inputProps={{
                      name: 'name',
                      value: this.state.name,
                      onChange: this.handleChange
                    }}
                    helpText="Ime i prezime"
                    labelText="Ime i prezime"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    inputProps={{
                      name: 'profession',
                      value: this.state.profession,
                      onChange: this.handleChange
                    }}
                    helpText="Zanimanje"
                    labelText="Zanimanje"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    inputProps={{
                      name: 'phone',
                      value: this.state.phone,
                      onChange: this.handleChange
                    }}
                    helpText="Telefon"
                    labelText="Telefon"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    inputProps={{
                      name: 'address',
                      value: this.state.address,
                      onChange: this.handleChange
                    }}
                    helpText="Adresa"
                    labelText="Adresa"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    inputProps={{
                      name: 'email',
                      value: this.state.email,
                      onChange: this.handleChange
                    }}
                    helpText="Email"
                    labelText="Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>

              <Button
                color="white"
                onClick={() => this.props.history.goBack()}
              >
                Natrag
              </Button>
              <Button
                color="info"
                onClick={this.create}
              >
                Kreiraj
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        {this.state.gdprConsentInvitationNumber &&
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <InsertDriveFile />
                </CardIcon>
                <h2 className={classes.cardIconTitle}>
                  Linkovi za privolu
                </h2>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      inputProps={{
                        value: acceptLink,
                        onChange: () => {},
                        readOnly: true,
                        endAdornment: (<InputAdornment position="end">
                          <MaterialButton onClick={() => {copy(acceptLink); showInfoMessage('Spremljeno u međuspremnik');}}>
                            <FileCopy />
                          </MaterialButton>
                        </InputAdornment>)
                      }}
                      helpText="Prihvati"
                      labelText="Prihvati"
                      formControlProps={{
                        fullWidth: true
                      }}
                      success={true}
                    />
                    {/* <CustomInput
                      inputProps={{
                        value: rejectLink,
                        onChange: () => {},
                        readOnly: true,
                        endAdornment: (<InputAdornment position="end">
                          <MaterialButton onClick={() => {copy(rejectLink); showInfoMessage('Spremljeno u međuspremnik');}}>
                            <FileCopy />
                          </MaterialButton>
                        </InputAdornment>)
                      }}
                      helpText="Odbij"
                      labelText="Odbij"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={true}
                    /> */}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        }
      </GridContainer>
    );
  }
}

export default withRouter(withStyles(styles)(NewCandidateInvitation));
