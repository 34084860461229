import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import postal from 'postal';
import auth0 from '../helpers/auth0';

import { MainContextProvider } from '../contexts/MainContext';

import cx from 'classnames';
import PropTypes from 'prop-types';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Header from '../components/Header/Header.jsx';
import Footer from '../components/Footer/Footer.jsx';
import Sidebar from '../components/Sidebar/Sidebar.jsx';

import Snackbars from '../components/Snackbar/Snackbar.jsx';

import appStyle from '../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import image from '../assets/img/sidebar-2.jpg';
import logo from '../assets/img/logo-white.svg';

import DashboardIcon from '@material-ui/icons/Dashboard';
// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import AddAlert from '@material-ui/icons/AddAlert';
import School from '@material-ui/icons/School';
import LockOpen from '@material-ui/icons/LockOpen';
import Mail from '@material-ui/icons/MailOutline';
import Call from '@material-ui/icons/Call';

var ps;

var dashboardRoutes = [
  {
    path: '/home',
    name: 'Početna',
    icon: DashboardIcon
  },
  {
    path: '/jobprofessions',
    name: 'Zanimanja',
    icon: School
  },
  {
    path: '/gdpr/consents',
    name: 'GDPR Privole',
    icon: LockOpen
  },
  {
    path: '/gdprconsentinvitations',
    name: 'Zahtjevi za privolu',
    icon: Call
  },
  {
    path: '/newsletters',
    name: 'Newsletter',
    icon: Mail
  },
  {
    path: '/jobadvertisements/new',
    name: 'Unos novog oglasa',
    icon: School,
    redirect: 'this property makes me invisible'
  },
  {
    path: '/jobadvertisements/edit',
    name: 'Izmjena oglasa',
    icon: School,
    redirect: 'this property makes me invisible'
  },
  {
    path: '/jobprofessions/new',
    name: 'Unos novog zanimanja',
    icon: School,
    redirect: 'this property makes me invisible'
  },
  {
    collapse: true,
    name: 'Predlošci',
    state: 'openComponents',
    icon: Apps,
    views: [
      {
        path: '/jobdescriptions',
        name: 'Opisi poslova',
        mini: 'OP',
      },
      {
        path: '/jobresponsibilities',
        name: 'Aktivnosti / Zadaci',
        mini: 'AZ',
      },
      {
        path: '/jobrequirements',
        name: 'Preduvjeti',
        mini: 'P'
      },
      {
        path: '/joboffers',
        name: 'Nudimo',
        mini: 'N',
      },
      {
        path: '/jobworkinfos',
        name: 'Radno vrijeme',
        mini: 'R',
      },
      {
        path: '/jobdurations',
        name: 'Trajanje',
        mini: 'T',
      },
      {
        path: '/jobmiscellaneouses',
        name: 'Ostalo',
        mini: 'O'
      }
    ]
  }
];

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      showNotification: false,
      notificationText: null
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.logout = this.logout.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }
  logout() {
    auth0.logout();
    window.location = '/';
  }
  componentDidMount() {
    this.logoutSubscription = postal.subscribe({
      channel: 'Main',
      topic: 'Main.logout',
      callback: this.logout
    });
    this.infoSubscription = postal.subscribe({
      channel: 'Main',
      topic: 'Main.info',
      callback: this.showNotification
    });
    this.ajaxSaveSubscription = postal.subscribe({
      channel: 'ajax',
      topic: 'ajax.save',
      callback: () => this.showNotification('Podaci su spremljeni')
    });
    this.ajaxDeleteSubscription = postal.subscribe({
      channel: 'ajax',
      topic: 'ajax.delete',
      callback: () => this.showNotification('Podaci su obrisani')
    });

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }
  componentWillUnmount() {
    if (this.logoutSubscription) {
      postal.unsubscribe(this.logoutSubscription);
    }
    if (this.infoSubscription) {
      postal.unsubscribe(this.infoSubscription);
    }
    if (this.ajaxSaveSubscription) {
      postal.unsubscribe(this.ajaxSaveSubscription);
    }
    if (this.ajaxDeleteSubscription) {
      postal.unsubscribe(this.ajaxDeleteSubscription);
    }

    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  showNotification(notificationText) {
    if (!this.state.showNotification) {
      this.setState({
        showNotification: true,
        notificationText
      });
      // use this to make the notification autoclose
      setTimeout( () => {
        this.setState({
          showNotification: false,
          notificationText: null
        });
      }, 5000);
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1
      });
    return (
      <MainContextProvider>
        <div className={classes.wrapper}>
          <Sidebar
            routes={dashboardRoutes}
            logoText={'Schanzer posao'}
            logo={logo}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="orange"
            bgColor="black"
            miniActive={this.state.miniActive}
            {...rest}
          />
          <div className={mainPanel} ref="mainPanel">
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              routes={dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container}>
                {this.props.children}
              </div>
            </div>
            <Footer fluid />
          </div>
          {
            this.state.showNotification &&
            <Snackbars
              place="tr"
              color="success"
              icon={AddAlert}
              message={this.state.notificationText}
              open={this.state.showNotification}
              closeNotification={() => this.setState({ showNotification: false, notificationText: null })}
              close
            />
          }
        </div>
      </MainContextProvider>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(appStyle)(Main));
