import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Button from '../../components/CustomButtons/Button.jsx';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/BorderColor';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

class GdprConsentTable extends React.Component {
  render() {
    const { classes } = this.props;

    const rows = this.props.items.map(i =>
      <TableRow key={i.id}>
        <TableCell component="th" scope="row">{i.consent}</TableCell>
        <TableCell component="th" scope="row">{i.context}</TableCell>
        <TableCell component="th" scope="row">{moment(i.date).format('Do MMMM YYYY, HH:mm')}</TableCell>
        <TableCell component="th" scope="row">
          <Button
            size="sm"
            color="success"
            onClick={() => this.props.onItemSelected(i)}
          >
            <EditIcon />
          </Button>
        </TableCell>
        <TableCell component="th" scope="row">
          <Button
            size="sm"
            color="danger"
            onClick={() => this.props.onItemDeleted(i)}
          >
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
    );

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <td style={{paddingLeft: '20px'}}>
                <Input
                  fullWidth={true}
                  placeholder="Filter..."
                  onChange={e => this.props.onTermFilterChanged(e.target.value)}
                />
              </td>
            </TableRow>
            <TableRow>
              <TableCell>Privola</TableCell>
              <TableCell>Kontekst</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.count}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.page}
          labelRowsPerPage="Br. stavki po stranici"
          labelDisplayedRows={obj => `${obj.from} - ${obj.to} od ${obj.count}, Stranica ${obj.page + 1}`}
          backIconButtonProps={{
            'aria-label': 'Prethodna',
          }}
          nextIconButtonProps={{
            'aria-label': 'Slijedeća',
          }}
          onChangePage={(p, l) => this.props.onChangePage(l + 1)}
          onChangeRowsPerPage={p => this.props.onChangeRowsPerPage(p.target.value)}
        />
      </Paper>
    );
  }
}

GdprConsentTable.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onItemDeleted: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onTermFilterChanged: PropTypes.func.isRequired
};
export default withStyles(styles)(GdprConsentTable);
