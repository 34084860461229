import React from 'react';
import auth0 from '../helpers/auth0';
import { withRouter } from 'react-router-dom';

import loginImg from '../assets/img/loginInProgress.png';

class LoginInProcess extends React.Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }
  componentDidMount() {
    if(!auth0.isUserLoggedIn()) {
      this.showSignin();
    }
  }
  redirect(url) {
    this.props.history.push(url);
  }
  showSignin() {
    auth0.login();
  }
  render() {
    if(this.props.errorMessage) {
      console.error(this.props.errorMessage);
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '500px'}}>
        <img src={loginImg} alt="Schanzer Posao" width="146" height="52"/>
        <h2>Molimo pričekajte. Prijava u tijeku</h2>
      </div>
    );
  }
}

export default withRouter(LoginInProcess);
