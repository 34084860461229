let appConfig = {
  auth0: {
    audence: 'Schanzer',
    clientId: 'C6UqAAkNpSHch0oGJH1ma0Te2kw82gmT',
    domain: 'event-horizon.eu.auth0.com',
    responseType: 'token id_token',
    scope: 'openid profile name email offline_access app_metadata user_metadata picture adminapi'
  }
};

if (process.env.NODE_ENV === 'production') {
  appConfig.serverUrl = '/';
} else {
  appConfig.serverUrl = 'http://localhost:5900/';
}

appConfig.apiUrl = appConfig.serverUrl;

export default appConfig;
