import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class YesNoDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  open() {
    this.setState({isOpen: true});
  }
  close() {
    this.setState({isOpen: false});
  }
  render() {
    return (
      <Dialog
        open={this.state.isOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.props.confirm(false)}>
            Ne
          </Button>
          <Button color="primary" autoFocus onClick={() => this.props.confirm(true)}>
            Da
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

YesNoDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirm: PropTypes.func.isRequired
};

export default YesNoDialog;
