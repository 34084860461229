import NewJobProfession from '../NewJobProfession';
import EditJobProfession from '../EditJobProfession';
import JobProfessionsList from '../JobProfessionsList';

const routes = [
  {path: '/jobprofessions', component: JobProfessionsList},
  {path: '/jobprofessions/new', component: NewJobProfession},
  {path: '/jobprofessions/edit/:id', component: EditJobProfession}
];

export default routes;
