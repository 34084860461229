import React from 'react';
import PropTypes from 'prop-types';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import withStyles from '@material-ui/core/styles/withStyles';
import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';

class JobTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValidated: false
    };

    this.changeName = this.changeName.bind(this);
    this.onTemplateStateChange = this.onTemplateStateChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isEmptyOrUndefined = this.isEmptyOrUndefined.bind(this);
  }
  changeName(e) {
    const value = e.target.value;
    this.setState({
      isValidated: true
    }, () => this.props.onNameChanged(value));
  }
  onTemplateStateChange(templateState) {
    this.props.onTemplateChanged(templateState);
  }
  isEmptyOrUndefined(value) {
    return !value || value.length === 0 || !value.trim();
  }
  isValid() {
    return !this.isEmptyOrUndefined(this.props.name);
  }
  render() {
    const { classes } = this.props;

    const toolbar = {
      options: ['inline', 'list' ]
    };

    const templateName = this.props.name
      ? this.props.name
      : 'Unesite novi predložak';

    const name = this.props.name || '';

    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <InsertDriveFile />
          </CardIcon>
          <h2 className={classes.cardIconTitle}>
            {templateName}
          </h2>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                inputProps={{
                  value: name,
                  onChange: this.changeName
                }}
                error={this.state.isValidated && !this.isValid()}
                helpText={
                  this.state.isValidated && !this.isValid()
                    ? 'Unesite naziv predloška'
                    :''
                }
                labelText="Naziv"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Editor
                toolbar={toolbar}
                editorState={this.props.template}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onTemplateStateChange}
              />
            </GridItem>
          </GridContainer>
          {this.props.showDelete &&
            <Button
              color="danger"
              onClick={() => {
                if (this.props.onDelete) {
                  this.props.onDelete();
                }
              }}
            >
              Obriši
            </Button>
          }
          {this.props.showCancel &&
            <Button
              color="white"
              onClick={() => {
                if (this.props.onCancel) {
                  this.props.onCancel();
                }
              }}
            >
              Odustani
            </Button>
          }
          <Button
            color="info"
            className={classes.updateProfileButton}
            disabled={!this.isValid()}
            onClick={this.props.onSave}
          >
            {this.props.saveLabel}
          </Button>
        </CardBody>
      </Card>
    );
  }
}

JobTemplate.defaultProps = {
  saveLabel: 'Unesi',
  showCancel: true,
  showDelete: false
};
JobTemplate.propTypes = {
  name: PropTypes.string,
  saveLabel: PropTypes.string,
  showCancel: PropTypes.bool,
  template: PropTypes.object,
  onNameChanged: PropTypes.func.isRequired,
  onTemplateChanged: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func
};

export default withStyles(userProfileStyles)(JobTemplate);
