import React from 'react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import request from '../../helpers/request';

import jobAdvertisementConfig from './config/jobAdvertisementConfig';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import withStyles from '@material-ui/core/styles/withStyles';
import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';

import YesNoDialog from '../common/YesNoDialog';
import ModalDialog from '../common/ModalDialog';
import ImageGallery from '../common/ImageGallery';

import { createImageUrl } from '../../helpers/imageHelpers';

import loadingImg from '../../assets/img/loadingRing.svg';

import _ from 'underscore';

import './jobAdvertisementStyles.css';

const dropzoneStyle = {
  width: '100%',
  minHeight: '140px',
  cursor: 'pointer',
  backgroundColor: '#f8fafb',
  border: '1px solid #e5e5e5',
  padding: '20px'
};

const styles = {
  ...userProfileStyles,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
  },
};

class TemplateSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };

    this.id = _.uniqueId('simple-');
    this.changeValue = this.changeValue.bind(this);
  }
  changeValue(value) {
    this.setState({value}, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  }
  render() {
    const { classes } = this.props;

    const menuItems = this.props.items.map(i =>
      <MenuItem key={i.value} value={i.value}>{i.name}</MenuItem>
    );

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={this.id}>Predložak</InputLabel>
        <Select
          value={this.state.value}
          inputProps={{
            id: this.id,
          }}
          onChange={e => this.changeValue(e.target.value)}
        >
          <MenuItem value="">
            <em>Poništi</em>
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    );
  }
}

class EditJobAdvertisement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasBeenFound: false,
      templates: null,
      id: null,
      number: null,
      published: false,
      title: '',
      titleDirectlyChanged: false,
      profession: '',
      location: '',
      description: EditorState.createEmpty(),
      responsibilities: EditorState.createEmpty(),
      requirements: EditorState.createEmpty(),
      offers: EditorState.createEmpty(),
      workInfo: EditorState.createEmpty(),
      duration: EditorState.createEmpty(),
      miscellaneous: EditorState.createEmpty(),
      uploading: false,
      images: [],
      uploadedImages: [],
      isFeaturedListing: false
    };

    this.changeTitle = this.changeTitle.bind(this);
    this.changeProfession = this.changeProfession.bind(this);
    this.changeTextProperty = this.changeTextProperty.bind(this);
    this.changeEditorStage = this.changeEditorStage.bind(this);
    this.applyProfessionTemplate = this.applyProfessionTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.save = this.save.bind(this);

    this.publish = this.publish.bind(this);

    this.htmlToState = this.htmlToState.bind(this);

    this.delete = this.delete.bind(this);

    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);

    this.deleteImage = this.deleteImage.bind(this);
  }
  onDropAccepted(files) {
    this.setState({uploading: true});

    request.upload(`${jobAdvertisementConfig.apiUrl}Upload/${this.props.match.params.id}`,
      files, result => {
        const uploadedImages = result
          ? result.map(i => ({
            src: createImageUrl(i.fullImage),
            thumbnail: createImageUrl(i.thumbnail)
          }))
          : [];

        this.setState({
          uploading: false,
          uploadedImages,
          images: _.union(this.state.images, uploadedImages)
        });
      });
  }
  onDropRejected() {
  }
  changeTitle(title) {
    this.setState({
      title,
      titleDirectlyChanged: true
    });
  }
  changeProfession(profession) {
    let title = this.state.title;
    if (!this.state.titleDirectlyChanged) {
      title = profession;
    }

    this.setState({title, profession});
  }
  getTemplates(templateName) {
    if (!this.state.templates || !this.state.templates[templateName]) {
      return [];
    }

    return this.state.templates[templateName];
  }
  applyProfessionTemplate(value) {
    const state = this.state;

    if (!state.templates || !state.templates.jobProfessions) {
      return;
    }

    const templateObj = state.templates.jobProfessions.find(t => t.id === value);

    if (!templateObj) {
      return;
    }

    this.changeProfession(templateObj.name);
  }
  applyTemplate(property, templateName, value) {
    const state = this.state;

    if (!state.templates || !state.templates[templateName]) {
      return;
    }

    const templateObj = state.templates[templateName].find(t => t.id === value);

    if (!templateObj) {
      return;
    }

    const html = templateObj.template;

    state[property] = this.htmlToState(html);

    this.setState(state);
  }
  htmlToState(html) {
    return !html
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromHTML(html));
  }
  changeEditorStage(property, newState) {
    const currentState = this.state;
    currentState[property] = newState;

    this.setState({currentState});
  }
  changeTextProperty(property, value) {
    const currentState = this.state;
    currentState[property] = value;

    this.setState({currentState});
  }
  componentDidMount() {
    request
      .get(`${jobAdvertisementConfig.apiUrl}GetDetails/${this.props.match.params.id}`, data => {
        this.setState({
          hasBeenFound: data.hasBeenFound,
          templates: data.templates.value,
          id: data.result.id,
          number: data.result.number,
          published: data.result.published,
          title: data.result.title,
          profession: data.result.profession,
          location: data.result.location,
          description: this.htmlToState(data.result.description),
          responsibilities: this.htmlToState(data.result.responsibilities),
          requirements: this.htmlToState(data.result.requirements),
          offers: this.htmlToState(data.result.offers),
          workInfo: this.htmlToState(data.result.workInfo),
          duration: this.htmlToState(data.result.duration),
          miscellaneous: this.htmlToState(data.result.miscellaneous),
          images: data.result.images,
          isFeaturedListing: data.result.isFeaturedListing
        });
      });
  }
  save() {
    request.put(
      `${jobAdvertisementConfig.apiUrl}Edit`,
      {
        id: this.state.id,
        title: this.state.title,
        profession: this.state.profession,
        description: convertToHTML(this.state.description.getCurrentContent()),
        responsibilities: convertToHTML(this.state.responsibilities.getCurrentContent()),
        requirements: convertToHTML(this.state.requirements.getCurrentContent()),
        offers: convertToHTML(this.state.offers.getCurrentContent()),
        workInfo: convertToHTML(this.state.workInfo.getCurrentContent()),
        duration: convertToHTML(this.state.duration.getCurrentContent()),
        Location: this.state.location,
        miscellaneous: convertToHTML(this.state.miscellaneous.getCurrentContent()),
        isFeaturedListing: this.state.isFeaturedListing
      },
      () => {}
    );
  }
  publish(doPublish) {
    if(doPublish) {
      request.put(
        `${jobAdvertisementConfig.apiUrl}Publish`,
        {
          id: this.props.match.params.id
        },
        published => {
          this.setState({published});
        }
      );
    } else {
      request.put(
        `${jobAdvertisementConfig.apiUrl}Unpublish`,
        {
          id: this.props.match.params.id
        },
        unpublished => {
          this.setState({published: !unpublished});
        }
      );
    }
  }
  delete(confirm) {
    this.deleteConfirmDialog.close();

    if(!confirm) {
      return;
    }

    request.del(
      `${jobAdvertisementConfig.apiUrl}Delete/${this.props.match.params.id}`,
      () => {
        this.props.history.goBack();
      }
    );
  }
  deleteImage(index) {
    const image = this.state.images[index];

    request.del(
      `${jobAdvertisementConfig.apiUrl}DeleteImage/${this.props.match.params.id}?fullImage=${image.fullImage}&thumbnail=${image.thumbnail}`,
      () => {
        let images = this.state.images;
        images.splice(index, 1);
        this.setState({images});
      }
    );
  }
  render() {
    if (!this.state.hasBeenFound) {
      return null;
    }

    const { classes } = this.props;

    const toolbar = {
      options: ['inline', 'list' ]
    };

    const title = this.state.title
      ? `${this.state.title} - ${this.state.number}`
      : 'Novi oglas';

    let images = [];
    if (this.state.images) {
      images = this.state.images.map(i => ({
        src: createImageUrl(i.fullImage),
        thumbnail: createImageUrl(i.thumbnail)
      }));
    }

    return (
      <div>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <InsertDriveFile />
            </CardIcon>
            <GridContainer>
              <GridItem xs={12} sm={10} md={11}>
                <h2 className={classes.cardIconTitle}>
                  {title}
                </h2>
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>
                <span className={classes.cardIconTitle}>Objavi</span>
                <Switch
                  checked={this.state.published}
                  onChange={e => this.publish(e.target.checked)}
                  color="primary"
                />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  inputProps={{
                    value: this.state.title,
                    onChange: e => this.changeTitle(e.target.value)
                  }}
                  helpText="Unesite naslov oglasa"
                  labelText="Naslov"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}></GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyProfessionTemplate(v)}
                    items={this.getTemplates('jobProfessions').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  inputProps={{
                    value: this.state.profession,
                    onChange: e => this.changeProfession(e.target.value)
                  }}
                  helpText="Unesite naziv zanimanja"
                  labelText="Zanimanje"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  inputProps={{
                    value: this.state.location,
                    onChange: e => this.changeTextProperty('location', e.target.value)
                  }}
                  helpText="Unesite lokaciju"
                  labelText="Lokacija"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <strong>Istaknuti oglas</strong>
                <Switch
                  checked={this.state.isFeaturedListing}
                  onChange={e => this.setState({isFeaturedListing: e.target.checked})}
                  color="primary"
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Opis posla</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('description', 'jobDescriptionTemplates', v)}
                      items={this.getTemplates('jobDescriptionTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('description', s)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Aktivnosti / Zadaci</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('responsibilities', 'jobResponsibilityTemplates', v)}
                      items={this.getTemplates('jobResponsibilityTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.responsibilities}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('responsibilities', s)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Preduvjeti</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('requirements', 'jobRequirementTemplates', v)}
                      items={this.getTemplates('jobRequirementTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.requirements}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('requirements', s)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Nudimo</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('offers', 'jobOffersTemplates', v)}
                      items={this.getTemplates('jobOffersTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.offers}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('offers', s)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Radno vrijeme</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('workInfo', 'jobWorkInfoTemplates', v)}
                      items={this.getTemplates('jobWorkInfoTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.workInfo}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('workInfo', s)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Trajanje</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('duration', 'jobDurationInfoTemplates', v)}
                      items={this.getTemplates('jobDurationInfoTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.duration}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('duration', s)}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9}>
                    <h4>Ostalo</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TemplateSelect
                      classes={classes}
                      onChange={v => this.applyTemplate('miscellaneous', 'jobMiscellaneousTemplates', v)}
                      items={this.getTemplates('jobMiscellaneousTemplates').map( t => ({
                        name: t.name,
                        value: t.id
                      }))}
                    />
                  </GridItem>
                </GridContainer>
                <Editor
                  toolbar={toolbar}
                  editorState={this.state.miscellaneous}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="customEditor"
                  onEditorStateChange={s => this.changeEditorStage('miscellaneous', s)}
                />
              </GridItem>
            </GridContainer>

            <Button
              color="white"
              onClick={() => this.props.history.goBack()}
            >
              Natrag
            </Button>
            <Button
              color="danger"
              onClick={() => this.deleteConfirmDialog.open()}
            >
              Obriši
            </Button>
            <Button
              color="info"
              onClick={this.save}
            >
              Spremi
            </Button>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PhotoCamera />
            </CardIcon>
            <h2 className={classes.cardIconTitle}>
              Fotografije
            </h2>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ImageGallery images={images} onDelete={this.deleteImage} />
              </GridItem>
            </GridContainer>
            <Button
              color="info"
              className={classes.updateProfileButton}
              onClick={() => this.photosDialog.open()}
            >
              Dodaj fotografije
            </Button>
          </CardBody>
        </Card>
        <YesNoDialog
          ref={c => this.deleteConfirmDialog = c}
          title="Da li ste sigurni?"
          message="Da li ste sigurni da želite obrisati oglas?"
          confirm={this.delete}
        />
        <ModalDialog
          ref={c => this.photosDialog = c}
          title="Dodavanje fotografija"
          onClose={() => this.setState({uploading: false, uploadedImages: []})}
        >
          <Dropzone
            name="files"
            accept="image/jpeg, image/png"
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            style={dropzoneStyle}
          >
            <h3 align="center">Spusti datoteke za pohranu</h3>
            <h4 align="center">(ili klikni)</h4>
            <div align="center">
              {this.state.uploading &&
                <img alt="" src={loadingImg} />
              }
              {this.state.uploadedImages.map((i, index) =>
                <img
                  key={index}
                  alt=""
                  src={i.thumbnail}
                  style={{maxWidth: '120px', maxHeight: '120px', padding: '5px'}}
                />
              )}
            </div>
          </Dropzone>
        </ModalDialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(EditJobAdvertisement));
