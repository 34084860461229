import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Nudimo - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetJobOffersTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobOffersTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobOffersTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobOffersTemplate`,
};

const JobOffers = () => <JobTemplateView {...templateProps} />;

export default JobOffers;
