import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import request from '../../helpers/request';

import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import Button from '../../components/CustomButtons/Button';

import JobProfessionsTable from './JobProfessionsTable';

import jobProfessionConfig from './config/jobProfessionConfig';

class JobProfessionsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobProfessions: [],
      term: '',
      page: 1,
      pageSize: 25,
      totalCount: 0,
      pageCount: 0,
    };

    this.refreshData = this.refreshData.bind(this);
    this.applyTermFilter = this.applyTermFilter.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.changePage = this.changePage.bind(this);

    this.onJobProfessionSelected = this.onJobProfessionSelected.bind(this);

    this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
  }
  changeRowsPerPage(pageSize) {
    this.setState({
      pageSize
    }, () => this.refreshData());
  }
  applyTermFilter(term) {
    this.setState({
      term
    }, () => this.refreshData());
  }
  changePage(selectedPage) {
    if (selectedPage === this.state.page) {
      return;
    }

    this.setState({page: selectedPage}, () => this.refreshData());
  }
  getQueryParams() {
    return {
      term: this.state.term,
      published: this.state.published,
      page: this.state.page,
      pageSize: this.state.pageSize
    };
  }
  onJobProfessionSelected(jobProfession) {
    this.props.history.push(`/jobprofessions/edit/${jobProfession.id}`);
  }
  refreshData() {
    request
      .get(`${jobProfessionConfig.apiUrl}GetAll`, this.getQueryParams(), response => {
        this.setState({
          jobProfessions: response.data,
          totalCount: response.count,
          pageCount: response.pageCount
        });
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  render() {
    return (
      <div>
        <Tooltip
          title="Dodaj novo zanimanje"
          placement="top"
        >
          <Button color="info" justIcon component={Link} to="jobprofessions/new">
            <AddIcon />
          </Button>
        </Tooltip>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <JobProfessionsTable
              page={this.state.page - 1}
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              items={this.state.jobProfessions}
              onItemSelected={this.onJobProfessionSelected}
              onChangeRowsPerPage={this.changeRowsPerPage}
              onChangePage={this.changePage}
              onTermFilterChanged={this.applyTermFilter}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(regularFormsStyle)(JobProfessionsList));
