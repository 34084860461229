import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import postal from 'postal';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Person from '@material-ui/icons/Person';

// core components
import Button from '../CustomButtons/Button.jsx';

import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle';

class HeaderLinks extends React.Component {
  state = {
    open: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  renderBasedOn(value) {
    console.log(value);
    return (
      <div>
        hansi
      </div>
    );
  }
  render() {
    const { classes, rtlActive } = this.props;

    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });

    return (
      <div className={wrapper}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ''
          }}
          onClick={() => postal.publish({channel: 'Main', topic: 'Main.logout'})}
        >
          <Person
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);
