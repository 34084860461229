import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import request from '../../helpers/request';

import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../../components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';

import GdprConsentInvitationTable from './GdprConsentInvitationTable';

import gdprConsentInvitationConfig from './config/gdprConsentInvitationConfig';

class CandidateInvitationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gdprConsentInvitations: [],
      page: 1,
      pageSize: 25,
      totalCount: 0,
      pageCount: 0,
      selectedGdprConsentInvitation: null
    };

    this.refreshData = this.refreshData.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.changePage = this.changePage.bind(this);

    this.onGdprConsentInvitationSelected = this.onGdprConsentInvitationSelected.bind(this);

    this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
  }
  changeRowsPerPage(pageSize) {
    this.setState({
      pageSize
    }, () => this.refreshData());
  }
  changePage(selectedPage) {
    if (selectedPage === this.state.page) {
      return;
    }

    this.setState({page: selectedPage}, () => this.refreshData());
  }
  getQueryParams() {
    return {
      page: this.state.page,
      pageSize: this.state.pageSize
    };
  }
  onGdprConsentInvitationSelected(gdprConsentInvitation) {
    if (!gdprConsentInvitation) {
      return;
    }

    const accepted = gdprConsentInvitation.status === 'Accepted';

    if (!accepted) {
      return;
    }

    this.setState({selectedGdprConsentInvitation: gdprConsentInvitation});
  }
  refreshData() {
    request
      .get(`${gdprConsentInvitationConfig.apiUrl}GetAll`, this.getQueryParams(), response => {
        this.setState({
          gdprConsentInvitations: response.data,
          totalCount: response.count,
          pageCount: response.pageCount
        });
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  render() {
    return (
      <div>
        <Tooltip
          title="Kreiraj novi poziv na privolu"
          placement="top"
        >
          <Button color="info" justIcon component={Link} to="gdprconsentinvitations/new">
            <AddIcon />
          </Button>
        </Tooltip>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GdprConsentInvitationTable
              page={this.state.page - 1}
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              items={this.state.gdprConsentInvitations}
              onItemSelected={this.onGdprConsentInvitationSelected}
              onChangeRowsPerPage={this.changeRowsPerPage}
              onChangePage={this.changePage}
            />
          </GridItem>
        </GridContainer>
        {this.state.selectedGdprConsentInvitation &&
          <Dialog
            open={!!this.state.selectedGdprConsentInvitation}
            onClose={() => this.setState({selectedGdprConsentInvitation: null})}
            aria-labelledby="model-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="modal-dialog-title">Detalji privole</DialogTitle>
            <form noValidate autoComplete="off">
              <TextField
                style={{
                  marginLeft: '15px',
                  marginRight: '15px',
                  width: '95%'
                }}
                label="Privola"
                variant="outlined"
                value={this.state.selectedGdprConsentInvitation.consent}
                onChange={() => {}}
              />
            </form>
            <DialogActions>
              <Button color="primary"
                onClick={() => this.setState({selectedGdprConsentInvitation: null})}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
}

export default withRouter(withStyles(regularFormsStyle)(CandidateInvitationList));
