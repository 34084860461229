import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import _ from 'underscore';

import Home from '../views/Home';
import Main from '../views/Main';
import ReportMain from '../reports/ReportMain';

import gdprConsentInvitationRoutes from '../views/gdprConsentInvitations/config/gdprConsentInvitationRoutes';
import gdprRoutes from '../views/gdpr/config/gdprRoutes';
import jobAdvertisementRoutes from '../views/jobAdvertisements/config/jobAdvertisementRoutes';
import jobProfessionRoutes from '../views/jobProfessions/config/jobProfessionRoutes';
import newsletterRoutes from '../views/newsletters/config/newsletterRoutes';
import templateRoutes from '../views/templates/config/templateRoutes';

import reportRoutes from '../reports/config/reportRoutes';

const RouteWithLayout = ({layout, component, ...rest}) => {
  return (
    <Route {...rest} render={(props) =>
      React.createElement( layout, props, React.createElement(component, props))
    }/>
  );
}

const mainRouteExpander = routes => routes.map(r =>
  <RouteWithLayout
    exact
    key={_.uniqueId('route-')}
    path={r.path}
    layout={Main}
    component={r.component}
  />
);
const reportRouteExpander = routes => routes.map(r =>
  <RouteWithLayout
    exact
    key={_.uniqueId('route-')}
    path={r.path}
    layout={ReportMain}
    component={r.component}
  />
);

const routes = (
  <Switch>
    {mainRouteExpander(gdprConsentInvitationRoutes)}
    {mainRouteExpander(gdprRoutes)}
    {mainRouteExpander(jobAdvertisementRoutes)}
    {mainRouteExpander(jobProfessionRoutes)}
    {mainRouteExpander(newsletterRoutes)}
    {mainRouteExpander(templateRoutes)}
    {reportRouteExpander(reportRoutes)}
    <RouteWithLayout exact path="/home" layout={Main} component={Home} />
    <Redirect from="/" to="/home" />
  </Switch>
);

export default routes;
