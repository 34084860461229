import Auth0Lock from 'auth0-lock';
import config from '../config/appConfig';

const
  ACCESS_TOKEN = 'auth0.accessToken',
  ID_TOKEN = 'auth0.idToken',
  REFRESH_TOKEN = 'auth0.refreshToken',
  USER_NAME = 'auth0.name',
  USER_EMAIL = 'auth0.email',
  USER_EXP = 'auth0.expiration',
  APP_METADATA = 'auth0.appMetadata',
  USER_METADATA = 'auth0.userMetadata',
  USER_PICTURE = 'auth0.picture';

class Auth0Service {
  constructor() {
    this.lock = new Auth0Lock(
      config.auth0.clientId,
      config.auth0.domain,
      {
        theme: {
          logo: 'https://drive.google.com/uc?id=11hiAutLuRbWsHDZd4AWzcyDugEf21se8',
          primaryColor: '#31324F'
        },
        language: 'hr',
        languageDictionary: {
          title: 'Schanzer posao Admin'
        },
        closable: false,
        auth: {
          audience: config.auth0.audence,
          redirectUrl: `${window.location.protocol}//${window.location.host}`,
          responseType: config.auth0.responseType,
          params: {
            scope: config.auth0.scope,
          },
        },
      },
    );

    this.lock.on('authenticated', authResult => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        window.location.reload();
      } else {
        window.location.reload();
        window.console.log('Error occured while signing in.');
      }
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }
  getLoggedInAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }
  getLoggedInIdToken() {
    return localStorage.getItem(ID_TOKEN);
  }
  getLoggedInRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }
  getLoggedInName() {
    return localStorage.getItem(USER_NAME);
  }
  getLoggedInEmail() {
    return localStorage.getItem(USER_EMAIL);
  }
  getLoggedInPicture() {
    return localStorage.getItem(USER_PICTURE);
  }
  getLoggedInUserMetadata() {
    return null;
  }
  getLoggedInAppMetadata() {
    return null;
  }
  getLoggedInExpirationDate() {
    const exp = localStorage.getItem(USER_EXP);
    return JSON.parse(exp);
  }
  login() {
    this.lock.show();
  }
  logout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(USER_EXP);
    localStorage.removeItem(USER_NAME);
    localStorage.removeItem(USER_EMAIL);
    localStorage.removeItem(APP_METADATA);
    localStorage.removeItem(USER_METADATA);
    localStorage.removeItem(USER_PICTURE);
    localStorage.removeItem(REFRESH_TOKEN);

    window.location.reload(); // TODO
  }
  setSession(authResult) {
    const expiresAt = JSON.stringify(
      (authResult.expiresIn * 1000) + new Date().getTime(),
    );

    localStorage.setItem(ACCESS_TOKEN, authResult.accessToken);
    localStorage.setItem(ID_TOKEN, authResult.idToken);
    localStorage.setItem(REFRESH_TOKEN, authResult.refreshToken);
    localStorage.setItem(USER_NAME, authResult.idTokenPayload.name);
    localStorage.setItem(USER_EMAIL, authResult.idTokenPayload.email);
    localStorage.setItem(USER_EXP, expiresAt);
    localStorage.setItem(USER_PICTURE, authResult.idTokenPayload.picture);
    localStorage.setItem(USER_METADATA, JSON.stringify(authResult.idTokenPayload.user_metadata));
    localStorage.setItem(APP_METADATA, JSON.stringify(authResult.idTokenPayload.app_metadata));
  }
  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem(USER_EXP));
    return new Date().getTime() < expiresAt;
  }
}

export default Auth0Service;
