import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Opisi poslova - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetAllJobDescriptionTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobDescriptionTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobDescriptionTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobDescriptionTemplate`,
};

const JobDescriptions = () => <JobTemplateView {...templateProps} />;

export default JobDescriptions;
