import React from 'react';
import PropTypes from 'prop-types';

import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import request from '../../helpers/request';

import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';

import Accordion from '../../components/Accordion/Accordion';
import Button from '../../components/CustomButtons/Button';

import JobTemplate from './JobTemplate';
import YesNoDialog from './YesNoDialog';

class JobTemplateView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNew: false,
      newName: '',
      newTemplate: EditorState.createEmpty(),
      templates: [],
      templateIdToDelete: null
    };

    this.showNewTemplateEntry = this.showNewTemplateEntry.bind(this);
    this.changeNewName = this.changeNewName.bind(this);
    this.changeNewTemplate = this.changeNewTemplate.bind(this);
    this.saveNew = this.saveNew.bind(this);
    this.cancelSaveNew = this.cancelSaveNew.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.changeTemplateName = this.changeTemplateName.bind(this);
    this.changeTemplateContent = this.changeTemplateContent.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.confirmDeleteTemplate = this.confirmDeleteTemplate.bind(this);
  }
  refreshData() {
    request
      .get(this.props.getApiUrl, data => {
        this.setState({
          templates: data.map(t => ({
            id: t.id,
            name: t.name,
            template: this.getStateFromHtml(t.template)
          }))
        });
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  showNewTemplateEntry() {
    this.setState({showNew: true});
  }
  getStateFromHtml(html) {
    if (!html) {
      return EditorState.createEmpty();
    }

    return EditorState.createWithContent(
      convertFromHTML(html)
    );
  }
  changeNewName(newName) {
    this.setState({
      newName,
      isValidated: true
    });
  }
  changeTemplateName(id, name) {
    const templates = this.state.templates;
    const index = templates.findIndex(t => t.id === id);

    if (index >= 0) {
      templates[index].name = name;
      this.setState({templates});
    }
  }
  changeTemplateContent(id, content) {
    const templates = this.state.templates;
    const index = templates.findIndex(t => t.id === id);

    if (index >= 0) {
      templates[index].template = content;
      this.setState({templates});
    }
  }
  changeNewTemplate(newTemplate) {
    this.setState({
      newTemplate
    });
  }
  saveTemplate(id) {
    const templates = this.state.templates;
    const index = templates.findIndex(t => t.id === id);

    if (index >= 0) {
      request.put(
        this.props.updateApiUrl,
        {
          id,
          name: templates[index].name,
          template: convertToHTML(templates[index].template.getCurrentContent())
        },
        () => {}
      );
    }
  }
  deleteTemplate(templateIdToDelete) {
    this.setState({templateIdToDelete}, () => {
      if (this.state.templateIdToDelete) {
        this.deleteConfirmDialog.open();
      }
    });
  }
  confirmDeleteTemplate(confirmed) {
    this.deleteConfirmDialog.close();

    if (confirmed) {
      const templates = this.state.templates;
      const index = templates.findIndex(t => t.id === this.state.templateIdToDelete);

      if (index >= 0) {
        request.del(
          `${this.props.deleteApiUrl}/${this.state.templateIdToDelete}`,
          () => {
            templates.splice(index, 1);
            this.setState({
              templates,
              templateIdToDelete: null
            });
          }
        );
      }
    } else {
      this.setState({
        templateIdToDelete: null
      });
    }
  }
  cancelSaveNew() {
    this.setState({
      showNew: false,
      newName: '',
      newTemplate: EditorState.createEmpty()
    });
  }
  saveNew() {
    request.post(
      this.props.newApiUrl,
      {
        name: this.state.newName,
        template: convertToHTML(this.state.newTemplate.getCurrentContent())
      },
      () => {
        this.setState({
          showNew: false,
          newName: '',
          newTemplate: EditorState.createEmpty()
        }, this.refreshData);
      }
    );
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        {!this.state.showNew &&
          <Tooltip
            id="tooltip-top"
            title="Dodaj novi predložak"
            placement="top"
          >
            <Button color="info" justIcon onClick={this.showNewTemplateEntry}>
              <AddIcon />
            </Button>
          </Tooltip>
        }
        {this.state.showNew &&
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <JobTemplate
                name={this.state.newName}
                template={this.state.newTemplate}
                onNameChanged={this.changeNewName}
                onTemplateChanged={this.changeNewTemplate}
                onSave={this.saveNew}
                onCancel={this.cancelSaveNew}
              />
            </GridItem>
          </GridContainer>
        }
        <br />
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <InsertDriveFile />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  {this.props.title}
                </h3>
              </CardHeader>
              <CardBody>
                <Accordion
                  active={-1}
                  collapses={
                    this.state.templates.map(t => ({
                      title: t.name,
                      content: (
                        <JobTemplate
                          name={t.name}
                          template={t.template}
                          saveLabel="Izmjeni"
                          showCancel={false}
                          showDelete={true}
                          onNameChanged={name => this.changeTemplateName(t.id, name)}
                          onTemplateChanged={content => this.changeTemplateContent(t.id, content)}
                          onSave={() => this.saveTemplate(t.id)}
                          onDelete={() => this.deleteTemplate(t.id)}
                        />
                      )
                    }))
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <YesNoDialog
          ref={c => this.deleteConfirmDialog = c}
          title="Da li ste sigurni?"
          message="Da li ste sigurni da želite obrisati odabrani predložak?"
          confirm={this.confirmDeleteTemplate}
        />
      </div>
    );
  }
}

JobTemplateView.propTypes = {
  title: PropTypes.string.isRequired,
  getApiUrl: PropTypes.string.isRequired,
  updateApiUrl: PropTypes.string.isRequired,
  deleteApiUrl: PropTypes.string.isRequired,
  newApiUrl: PropTypes.string.isRequired,
};

export default withStyles(regularFormsStyle)(JobTemplateView);
