import React from 'react';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import request from '../../helpers/request';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import withStyles from '@material-ui/core/styles/withStyles';
import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';

import jobProfessionConfig from './config/jobProfessionConfig';

import YesNoDialog from '../common/YesNoDialog';
import ModalDialog from '../common/ModalDialog';
import ImageGallery from '../common/ImageGallery';

import { createImageUrl } from '../../helpers/imageHelpers';

import loadingImg from '../../assets/img/loadingRing.svg';

import _ from 'underscore';

const dropzoneStyle = {
  width: '100%',
  minHeight: '140px',
  cursor: 'pointer',
  backgroundColor: '#f8fafb',
  border: '1px solid #e5e5e5',
  padding: '20px'
};

class EditNewJobProfession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasBeenFound: false,
      id: null,
      name: '',
      isValidated: false,
      uploading: false,
      images: [],
      uploadedImages: []
    };

    this.changeName = this.changeName.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isEmptyOrUndefined = this.isEmptyOrUndefined.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);

    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);

    this.deleteImage = this.deleteImage.bind(this);
  }
  onDropAccepted(files) {
    this.setState({uploading: true});

    request.upload(`${jobProfessionConfig.apiUrl}Upload/${this.props.match.params.id}`,
      files, result => {
        const uploadedImages = result
          ? result.map(i => ({
            src: createImageUrl(i.fullImage),
            thumbnail: createImageUrl(i.thumbnail)
          }))
          : [];

        this.setState({
          uploading: false,
          uploadedImages,
          images: _.union(this.state.images, uploadedImages)
        });
      });
  }
  onDropRejected() {
  }
  changeName(e) {
    const name = e.target.value;
    this.setState({
      isValidated: true,
      name
    });
  }
  isEmptyOrUndefined(value) {
    return !value || value.length === 0 || !value.trim();
  }
  isValid() {
    return !this.isEmptyOrUndefined(this.state.name);
  }
  componentDidMount() {
    request
      .get(`${jobProfessionConfig.apiUrl}GetDetails/${this.props.match.params.id}`, data => {
        this.setState({
          hasBeenFound: !!data,
          ...data,
        });
      });
  }
  delete(confirm) {
    this.deleteConfirmDialog.close();

    if(!confirm) {
      return;
    }

    request.del(
      `${jobProfessionConfig.apiUrl}Delete/${this.props.match.params.id}`,
      () => {
        this.props.history.goBack();
      }
    );
  }
  deleteImage(index) {
    const image = this.state.images[index];

    request.del(
      `${jobProfessionConfig.apiUrl}DeleteImage/${this.props.match.params.id}?fullImage=${image.fullImage}&thumbnail=${image.thumbnail}`,
      () => {
        let images = this.state.images;
        images.splice(index, 1);
        this.setState({images});
      }
    );
  }
  save() {
    request.put(
      `${jobProfessionConfig.apiUrl}Edit`,
      {
        id: this.state.id,
        name: this.state.name
      },
      () => {}
    );
  }
  render() {
    const { classes } = this.props;

    let images = [];
    if (this.state.images) {
      images = this.state.images.map(i => ({
        src: createImageUrl(i.fullImage),
        thumbnail: createImageUrl(i.thumbnail)
      }));
    }

    return (
      <div>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <InsertDriveFile />
            </CardIcon>
            <h2 className={classes.cardIconTitle}>
              {this.state.name}
            </h2>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  inputProps={{
                    value: this.state.name,
                    onChange: this.changeName
                  }}
                  error={this.state.isValidated && !this.isValid()}
                  helpText={
                    this.state.isValidated && !this.isValid()
                      ? 'Unesite naziv zanimanja'
                      :''
                  }
                  labelText="Naziv"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>
            <Button
              color="white"
              onClick={() => this.props.history.goBack()}
            >
              Natrag
            </Button>
            <Button
              color="danger"
              onClick={() => this.deleteConfirmDialog.open()}
            >
              Obriši
            </Button>
            <Button
              color="info"
              className={classes.updateProfileButton}
              disabled={!this.isValid()}
              onClick={this.save}
            >
              Spremi
            </Button>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PhotoCamera />
            </CardIcon>
            <h2 className={classes.cardIconTitle}>
              Fotografije
            </h2>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ImageGallery images={images} onDelete={this.deleteImage} />
              </GridItem>
            </GridContainer>
            <Button
              color="info"
              className={classes.updateProfileButton}
              onClick={() => this.photosDialog.open()}
            >
              Dodaj fotografije
            </Button>
          </CardBody>
        </Card>
        <YesNoDialog
          ref={c => this.deleteConfirmDialog = c}
          title="Da li ste sigurni?"
          message="Da li ste sigurni da želite obrisati zanimanje?"
          confirm={this.delete}
        />
        <ModalDialog
          ref={c => this.photosDialog = c}
          title="Dodavanje fotografija"
          onClose={() => this.setState({uploading: false, uploadedImages: []})}
        >
          <Dropzone
            name="files"
            accept="image/jpeg, image/png"
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            style={dropzoneStyle}
          >
            <h3 align="center">Spusti datoteke za pohranu</h3>
            <h4 align="center">(ili klikni)</h4>
            <div align="center">
              {this.state.uploading &&
                <img alt="" src={loadingImg} />
              }
              {this.state.uploadedImages.map((i, index) =>
                <img
                  key={index}
                  alt=""
                  src={i.thumbnail}
                  style={{maxWidth: '120px', maxHeight: '120px', padding: '5px'}}
                />
              )}
            </div>
          </Dropzone>
        </ModalDialog>
      </div>
    );
  }
}

export default withRouter(withStyles(userProfileStyles)(EditNewJobProfession));
