import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Aktivnosti / Zadaci - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetAllJobResponsibilityTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobResponsibilityTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobResponsibilityTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobResponsibilityTemplate`,
};

const JobResponsibilities = () => <JobTemplateView {...templateProps} />;

export default JobResponsibilities;
