import React from 'react';
import { withRouter } from 'react-router-dom';

import request from '../../helpers/request';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import withStyles from '@material-ui/core/styles/withStyles';
import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';

import jobProfessionConfig from './config/jobProfessionConfig';

class NewJobProfession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      isValidated: false
    };

    this.changeName = this.changeName.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isEmptyOrUndefined = this.isEmptyOrUndefined.bind(this);
    this.save = this.save.bind(this);
  }
  changeName(e) {
    const name = e.target.value;
    this.setState({
      isValidated: true,
      name
    });
  }
  isEmptyOrUndefined(value) {
    return !value || value.length === 0 || !value.trim();
  }
  isValid() {
    return !this.isEmptyOrUndefined(this.state.name);
  }
  save() {
    request.post(
      `${jobProfessionConfig.apiUrl}Create`,
      {
        name: this.state.name
      },
      jobProfession => {
        this.props.history.push(`/jobProfessions/edit/${jobProfession.id}`);
      }
    );
  }
  render() {
    const { classes } = this.props;

    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <InsertDriveFile />
          </CardIcon>
          <h2 className={classes.cardIconTitle}>
            Unesite novo zanimanje
          </h2>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                inputProps={{
                  value: this.state.name,
                  onChange: this.changeName
                }}
                error={this.state.isValidated && !this.isValid()}
                helpText={
                  this.state.isValidated && !this.isValid()
                    ? 'Unesite naziv zanimanja'
                    :''
                }
                labelText="Naziv"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </GridContainer>
          <Button
            color="white"
            onClick={() => this.props.history.goBack()}
          >
            Natrag
          </Button>
          <Button
            color="info"
            className={classes.updateProfileButton}
            disabled={!this.isValid()}
            onClick={this.save}
          >
            Spremi
          </Button>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(withStyles(userProfileStyles)(NewJobProfession));
