import React from 'react';
import { withRouter } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import request from '../../helpers/request';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import NewsletterTable from './NewsletterTable';

import newsletterConfig from './config/newsletterConfig';

class NewsletterList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsletters: [],
      term: '',
      page: 1,
      pageSize: 25,
      totalCount: 0,
      pageCount: 0,
    };

    this.refreshData = this.refreshData.bind(this);
    this.applyTermFilter = this.applyTermFilter.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.changePage = this.changePage.bind(this);

    this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
  }
  changeRowsPerPage(pageSize) {
    this.setState({
      pageSize
    }, () => this.refreshData());
  }
  applyTermFilter(term) {
    this.setState({
      term
    }, () => this.refreshData());
  }
  changePage(selectedPage) {
    if (selectedPage === this.state.page) {
      return;
    }

    this.setState({page: selectedPage}, () => this.refreshData());
  }
  getQueryParams() {
    return {
      term: this.state.term,
      published: this.state.published,
      page: this.state.page,
      pageSize: this.state.pageSize
    };
  }
  refreshData() {
    request
      .get(`${newsletterConfig.apiUrl}GetAll`, this.getQueryParams(), response => {
        this.setState({
          newsletters: response.data,
          totalCount: response.count,
          pageCount: response.pageCount
        });
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <NewsletterTable
              page={this.state.page - 1}
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              items={this.state.newsletters}
              onChangeRowsPerPage={this.changeRowsPerPage}
              onChangePage={this.changePage}
              onTermFilterChanged={this.applyTermFilter}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(regularFormsStyle)(NewsletterList));
