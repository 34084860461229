import React from 'react';
import auth0 from '../helpers/auth0';

const MainContext = React.createContext();

class MainContextProvider extends React.Component {
  constructor(props) {
    super(props);

    const token = auth0.getTokenDataFromCache();

    this.state = {
      userName: token.name,
      userProfileImage: token.picture
    };
  }
  render() {
    return (
      <MainContext.Provider value={this.state}>
        {this.props.children}
      </MainContext.Provider>
    );
  }
}

export {
  MainContext,
  MainContextProvider
};
