import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Trajanje - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetJobDurationInfoTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobDurationInfoTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobDurationInfoTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobDurationInfoTemplate`,
};

const JobDurations = () => <JobTemplateView {...templateProps} />;

export default JobDurations;
