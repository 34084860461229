import appConfig from '../../../config/appConfig';

var config = {
  apiUrl: appConfig.apiUrl + 'api/jobProfessions/',
  validationRules: {
    name: {
      presence: true
    }
  }
};

export default config;