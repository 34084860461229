import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes from './config/routes';
import LoginInProcess from './views/LoginInProcess';
import auth0 from './helpers/auth0';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserLoggedIn: auth0.isUserLoggedIn()
    };
  }
  render() {
    if (this.state.isUserLoggedIn) {
      return (
        <BrowserRouter>
          {routes}
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <LoginInProcess />
        </BrowserRouter>
      );
    }
  }
}

export default App;
