import JobDescriptions from '../JobDescriptions';
import JobResponsibilities from '../JobResponsibilities';
import JobRequirements from '../JobRequirements';
import JobOffers from '../JobOffers';
import JobWorkInfos from '../JobWorkInfos';
import JobDurations from '../JobDurations';
import JobMiscellaneouses from '../JobMiscellaneouses';

const routes = [
  {path: '/jobdescriptions', component: JobDescriptions},
  {path: '/jobresponsibilities', component: JobResponsibilities},
  {path: '/jobrequirements', component: JobRequirements},
  {path: '/joboffers', component: JobOffers},
  {path: '/jobworkinfos', component: JobWorkInfos},
  {path: '/jobdurations', component: JobDurations},
  {path: '/jobmiscellaneouses', component: JobMiscellaneouses},
];

export default routes;
