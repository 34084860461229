import React from 'react';
import { withRouter } from 'react-router-dom';

import request from '../../helpers/request';

import jobAdvertisementConfig from './config/jobAdvertisementConfig';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardIcon from '../../components/Card/CardIcon.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';

import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import withStyles from '@material-ui/core/styles/withStyles';
import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import _ from 'underscore';

import './jobAdvertisementStyles.css';

const styles = {
  ...userProfileStyles,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
  },
};

class TemplateSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };

    this.id = _.uniqueId('simple-');
    this.changeValue = this.changeValue.bind(this);
  }
  changeValue(value) {
    this.setState({value}, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  }
  render() {
    const { classes } = this.props;

    const menuItems = this.props.items.map(i =>
      <MenuItem key={i.value} value={i.value}>{i.name}</MenuItem>
    );

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={this.id}>Predložak</InputLabel>
        <Select
          value={this.state.value}
          inputProps={{
            id: this.id,
          }}
          onChange={e => this.changeValue(e.target.value)}
        >
          <MenuItem value="">
            <em>Poništi</em>
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    );
  }
}

class NewJobAdvertisement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: null,
      title: '',
      titleDirectlyChanged: false,
      profession: '',
      location: 'Njemačka',
      description: EditorState.createEmpty(),
      responsibilities: EditorState.createEmpty(),
      requirements: EditorState.createEmpty(),
      offers: EditorState.createEmpty(),
      workInfo: EditorState.createEmpty(),
      duration: EditorState.createEmpty(),
      miscellaneous: EditorState.createEmpty(),
      isFeaturedListing: false
    };

    this.changeTitle = this.changeTitle.bind(this);
    this.changeProfession = this.changeProfession.bind(this);
    this.changeTextProperty = this.changeTextProperty.bind(this);
    this.changeEditorStage = this.changeEditorStage.bind(this);
    this.applyProfessionTemplate = this.applyProfessionTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.save = this.save.bind(this);
  }
  changeTitle(title) {
    this.setState({
      title,
      titleDirectlyChanged: true
    });
  }
  changeProfession(profession) {
    let title = this.state.title;
    if (!this.state.titleDirectlyChanged) {
      title = profession;
    }

    this.setState({title, profession});
  }
  getTemplates(templateName) {
    if (!this.state.templates || !this.state.templates[templateName]) {
      return [];
    }

    return this.state.templates[templateName];
  }
  applyProfessionTemplate(value) {
    const state = this.state;

    if (!state.templates || !state.templates.jobProfessions) {
      return;
    }

    const templateObj = state.templates.jobProfessions.find(t => t.id === value);

    if (!templateObj) {
      return;
    }

    this.changeProfession(templateObj.name);
  }
  applyTemplate(property, templateName, value) {
    const state = this.state;

    if (!state.templates || !state.templates[templateName]) {
      return;
    }

    const templateObj = state.templates[templateName].find(t => t.id === value);

    if (!templateObj) {
      return;
    }

    const html = templateObj.template;
    const templateState = !html
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromHTML(html));

    state[property] = templateState;

    this.setState(state);
  }
  changeEditorStage(property, newState) {
    const currentState = this.state;
    currentState[property] = newState;

    this.setState({currentState});
  }
  changeTextProperty(property, value) {
    const currentState = this.state;
    currentState[property] = value;

    this.setState({currentState});
  }
  componentDidMount() {
    request
      .get(`${jobAdvertisementConfig.apiUrl}Templates`, data => {
        this.setState({
          templates: data
        });
      });
  }
  save() {
    request.post(
      `${jobAdvertisementConfig.apiUrl}Create`,
      {
        title: this.state.title,
        profession: this.state.profession,
        description: convertToHTML(this.state.description.getCurrentContent()),
        responsibilities: convertToHTML(this.state.responsibilities.getCurrentContent()),
        requirements: convertToHTML(this.state.requirements.getCurrentContent()),
        offers: convertToHTML(this.state.offers.getCurrentContent()),
        workInfo: convertToHTML(this.state.workInfo.getCurrentContent()),
        duration: convertToHTML(this.state.duration.getCurrentContent()),
        Location: this.state.location,
        miscellaneous: convertToHTML(this.state.miscellaneous.getCurrentContent()),
        isFeaturedListing: this.state.isFeaturedListing
      },
      advertisement => {
        this.props.history.push(`/jobadvertisements/edit/${advertisement.id}`);
      }
    );
  }
  render() {
    const { classes } = this.props;

    const toolbar = {
      options: ['inline', 'list' ]
    };

    const title = this.state.title
      ? this.state.title
      : 'Novi oglas';

    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <InsertDriveFile />
          </CardIcon>
          <h2 className={classes.cardIconTitle}>
            {title}
          </h2>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                inputProps={{
                  value: this.state.title,
                  onChange: e => this.changeTitle(e.target.value)
                }}
                helpText="Unesite naslov oglasa"
                labelText="Naslov"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={10} md={9}></GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TemplateSelect
                  classes={classes}
                  onChange={v => this.applyProfessionTemplate(v)}
                  items={this.getTemplates('jobProfessions').map( t => ({
                    name: t.name,
                    value: t.id
                  }))}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                inputProps={{
                  value: this.state.profession,
                  onChange: e => this.changeProfession(e.target.value)
                }}
                helpText="Unesite naziv zanimanja"
                labelText="Zanimanje"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                inputProps={{
                  value: this.state.location,
                  onChange: e => this.changeTextProperty('location', e.target.value)
                }}
                helpText="Unesite lokaciju"
                labelText="Lokacija"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <strong>Istaknuti oglas</strong>
              <Switch
                checked={this.state.isFeaturedListing}
                onChange={e => this.setState({isFeaturedListing: e.target.checked})}
                color="primary"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Opis posla</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('description', 'jobDescriptionTemplates', v)}
                    items={this.getTemplates('jobDescriptionTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('description', s)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Aktivnosti / Zadaci</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('responsibilities', 'jobResponsibilityTemplates', v)}
                    items={this.getTemplates('jobResponsibilityTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.responsibilities}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('responsibilities', s)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Preduvjeti</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('requirements', 'jobRequirementTemplates', v)}
                    items={this.getTemplates('jobRequirementTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.requirements}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('requirements', s)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Nudimo</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('offers', 'jobOffersTemplates', v)}
                    items={this.getTemplates('jobOffersTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.offers}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('offers', s)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Radno vrijeme</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('workInfo', 'jobWorkInfoTemplates', v)}
                    items={this.getTemplates('jobWorkInfoTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.workInfo}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('workInfo', s)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Trajanje</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('duration', 'jobDurationInfoTemplates', v)}
                    items={this.getTemplates('jobDurationInfoTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.duration}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('duration', s)}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={10} md={9}>
                  <h4>Ostalo</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TemplateSelect
                    classes={classes}
                    onChange={v => this.applyTemplate('miscellaneous', 'jobMiscellaneousTemplates', v)}
                    items={this.getTemplates('jobMiscellaneousTemplates').map( t => ({
                      name: t.name,
                      value: t.id
                    }))}
                  />
                </GridItem>
              </GridContainer>
              <Editor
                toolbar={toolbar}
                editorState={this.state.miscellaneous}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="customEditor"
                onEditorStateChange={s => this.changeEditorStage('miscellaneous', s)}
              />
            </GridItem>
          </GridContainer>

          <Button
            color="white"
            onClick={() => this.props.history.goBack()}
          >
            Natrag
          </Button>
          <Button
            color="info"
            onClick={this.save}
          >
            Spremi
          </Button>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(NewJobAdvertisement));
