import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class GdprConsentRevokeReport extends Component {
  componentDidMount() {
    window.print();
  }
  render() {
    const name = this.props.match.params.name;

    const centerStyle = {
      textAlign: 'center'
    };

    return (
      <div>
        <div>
          Schanzer posao d.o.o.<br />
          Jurišićeva ul. 19/1<br />
          HR-10000 Zagreb <br />
          OIB: 32266907102
        </div>
        <div style={{float: 'right'}}>
          Zagreb, {moment().format('Do MMMM YYYY')}
        </div>
        <div style={{clear: 'both'}}></div>
        <br />
        <br />
        <div>
          <h1 style={centerStyle}>OPOZIV PRIVOLE</h1>
          <h2 style={centerStyle}>Brisanje iz baze podataka</h2>
        </div>
        <br />
        <br />
        <br />
        <strong>Poštovani {name},</strong>
        <br />
        <p>
        Opoziv privole za obradu osobnih podataka je izvršen na dan {moment().format('Do MMMM YYYY')}.
        </p>
        <p>
        Ovim dokumentom Schanzer d.o.o. potvrđuje da su svi podaci za koje ste prethodno dali privolu, od današnjeg dana obrisani iz naše baze podataka.
        </p>
      </div>
    );
  }
}

export default withRouter(GdprConsentRevokeReport);
