import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

import Badge from '../../components/Badge/Badge';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
  },
});

class StatusSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: -1
    };

    this.changeValue = this.changeValue.bind(this);
  }
  changeValue(value) {
    this.setState({value}, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <FormControl className={classes.formControl}>
        <Select
          value={this.state.value}
          inputProps={{
            id: this.id,
          }}
          onChange={e => this.changeValue(e.target.value)}
        >
          <MenuItem value={-1}>
            <em>Sve</em>
          </MenuItem>
          <MenuItem value={0}>
            <em>Nije objavljeno</em>
          </MenuItem>
          <MenuItem value={1}>
            <em>Objavljeno</em>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
}

class JobAdvertisementTable extends React.Component {
  render() {
    const { classes } = this.props;

    const rows = this.props.items.map(i =>
      <TableRow
        hover
        key={i.id}
        onClick={() => this.props.onItemSelected(i)}
      >
        <TableCell>{i.number}</TableCell>
        <TableCell component="th" scope="row">{i.title}</TableCell>
        <TableCell>{i.profession}</TableCell>
        <TableCell>{moment(i.createdAt).format('Do MMMM YYYY, HH:mm')}</TableCell>
        <TableCell>{moment(i.updatedAt).format('Do MMMM YYYY, HH:mm')}</TableCell>
        <TableCell>
          {
            i.published
              ? <Badge color="success">Objavljeno</Badge>
              : <Badge color="warning">Nije objavljeno</Badge>
          }
        </TableCell>
      </TableRow>
    );

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <td style={{paddingLeft: '20px'}}>
                <Input
                  fullWidth={true}
                  placeholder="Broj..."
                  onChange={e => this.props.onNumberFilterChanged(e.target.value)}
                />
              </td>
              <td style={{paddingLeft: '20px'}}>
                <Input
                  fullWidth={true}
                  placeholder="Filter..."
                  onChange={e => this.props.onTermFilterChanged(e.target.value)}
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{paddingLeft: '20px', paddingRight: '20px'}}>
                <StatusSelect
                  classes={classes}
                  onChange={v => this.props.onStatusFilterChanged(v)}
                />
              </td>
            </TableRow>
            <TableRow>
              <TableCell>Broj</TableCell>
              <TableCell>Naslov</TableCell>
              <TableCell>Zanimanje</TableCell>
              <TableCell>Kreirano</TableCell>
              <TableCell>Zadnja izmjena</TableCell>
              <TableCell>Objavljeno</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.count}
          rowsPerPage={this.props.rowsPerPage}
          page={this.props.page}
          labelRowsPerPage="Br. stavki po stranici"
          labelDisplayedRows={obj => `${obj.from} - ${obj.to} od ${obj.count}, Stranica ${obj.page + 1}`}
          backIconButtonProps={{
            'aria-label': 'Prethodna',
          }}
          nextIconButtonProps={{
            'aria-label': 'Slijedeća',
          }}
          onChangePage={(p, l) => this.props.onChangePage(l + 1)}
          onChangeRowsPerPage={p => this.props.onChangeRowsPerPage(p.target.value)}
        />
      </Paper>
    );
  }
}

JobAdvertisementTable.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onNumberFilterChanged: PropTypes.func.isRequired,
  onTermFilterChanged: PropTypes.func.isRequired,
  onStatusFilterChanged: PropTypes.func.isRequired,
};
export default withStyles(styles)(JobAdvertisementTable);
