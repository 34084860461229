import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Ostalo - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetJobMiscellaneousTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobMiscellaneousTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobMiscellaneousTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobMiscellaneousTemplate`,
};

const JobMiscellaneouses = () => <JobTemplateView {...templateProps} />;

export default JobMiscellaneouses;
