import AuthService from '../security/AuthService';

const authService = new AuthService();

function getTokenDataFromCache() {
  const accessToken = authService.getLoggedInAccessToken();
  const idToken = authService.getLoggedInIdToken();
  const refreshToken = authService.getLoggedInRefreshToken();
  const exp = authService.getLoggedInExpirationDate();

  let isExpired = true;
  let expirationDate = null;
  if(exp) {
    isExpired = expirationDate < new Date().getTime();
  }

  let userName = authService.getLoggedInName();
  const userMetadataStorage = authService.getLoggedInUserMetadata();
  let userMetadataValue = null;

  if (userMetadataStorage && (userMetadataStorage !== 'undefined') && userMetadataStorage.name) {
    userName = userMetadataStorage.name;
  }

  let auth0Data = {
    accessToken,
    idToken,
    refreshToken,
    name: userName,
    email: authService.getLoggedInEmail(),
    expirationDate,
    isExpired,
    isAuthenticated: idToken && !isExpired,
    picture: authService.getLoggedInPicture(),
    appMetadata: authService.getLoggedInAppMetadata(),
    userMetadata: userMetadataValue
  };

  return auth0Data;
}

function getAccessToken() {
  return authService.getLoggedInAccessToken();
}
function getIdToken() {
  return authService.getLoggedInIdToken();
}

function rememberCurrentLocation(/*redirectCbParam*/) {
  // TODO
}

/*
function restoreLocation() {
  // TODO
}
*/

function refreshToken(cb) {
  // TODO
}

function login() {
  authService.login();
}

function logout() {
  authService.logout();
}

function isUserLoggedIn() {
  return authService.isAuthenticated();
}

export default {
  getAccessToken,
  getIdToken,
  login,
  logout,
  getTokenDataFromCache,
  refreshToken,
  rememberCurrentLocation,
  isUserLoggedIn
};
