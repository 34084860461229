import React from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import request from '../helpers/request';
import jobAdvertisementConfig from '../views/jobAdvertisements/config/jobAdvertisementConfig';

import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Button from '../components/CustomButtons/Button';

import JobAdvertisementTable from './jobAdvertisements/JobAdvertisementTable';

import appStyles from '../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advertisements: [],
      term: '',
      number: '',
      published: null,
      page: 1,
      pageSize: 25,
      totalCount: 0,
      pageCount: 0,
    };

    this.refreshData = this.refreshData.bind(this);
    this.applyTermFilter = this.applyTermFilter.bind(this);
    this.applyStatusFilter = this.applyStatusFilter.bind(this);
    this.applyNumberFilter = this.applyNumberFilter.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.changePage = this.changePage.bind(this);

    this.onAdvertisementSelected = this.onAdvertisementSelected.bind(this);

    this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
  }
  changeRowsPerPage(pageSize) {
    this.setState({
      pageSize
    }, () => this.refreshData());
  }
  applyTermFilter(term) {
    this.setState({
      term
    }, () => this.refreshData());
  }
  applyNumberFilter(number) {
    this.setState({
      number
    }, () => this.refreshData());
  }
  applyStatusFilter(status) {
    let published = null;

    if (status === 0) {
      published = false;
    } else if (status === 1) {
      published = true;
    }

    this.setState({
      published
    }, () => this.refreshData());
  }
  changePage(selectedPage) {
    if (selectedPage === this.state.page) {
      return;
    }

    this.setState({page: selectedPage}, () => this.refreshData());
  }
  getQueryParams() {
    return {
      term: this.state.term,
      number: this.state.number,
      published: this.state.published,
      page: this.state.page,
      pageSize: this.state.pageSize
    };
  }
  onAdvertisementSelected(advertisement) {
    this.props.history.push(`/jobadvertisements/edit/${advertisement.id}`);
  }
  refreshData() {
    request
      .get(`${jobAdvertisementConfig.apiUrl}GetAll`, this.getQueryParams(), response => {
        this.setState({
          advertisements: response.data,
          totalCount: response.count,
          pageCount: response.pageCount
        });
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  render() {
    return (
      <div>
        <Tooltip
          title="Dodaj novi oglas"
          placement="top"
        >
          <Button color="info" justIcon component={Link} to="jobadvertisements/new">
            <AddIcon />
          </Button>
        </Tooltip>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <JobAdvertisementTable
              page={this.state.page - 1}
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              items={this.state.advertisements}
              onItemSelected={this.onAdvertisementSelected}
              onChangeRowsPerPage={this.changeRowsPerPage}
              onChangePage={this.changePage}
              onTermFilterChanged={this.applyTermFilter}
              onNumberFilterChanged={this.applyNumberFilter}
              onStatusFilterChanged={this.applyStatusFilter}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withRouter(withStyles(appStyles)(Home));
