import request from 'superagent';
import postal from 'postal';
import auth0 from './auth0';

var channel = postal.channel('ajax');

const serverCommunicationError = '...pri komunikaciji s poslužiteljem.';

const handleError = (err, res) => {
  if (err.status === 400) {

    var errorMessage = '...validacijom prenesenih podataka.';

    if (res && res.text) {

      try {
        var json = JSON.parse(res.text);
        if (json && json.message) {
          errorMessage = json.message;
        }
      } catch (e) {}
    }

    channel.publish('ajaxerror.add', {
      message: errorMessage
    });

    if(res.body && res.body.errors){
      res.body.errors
        .forEach(x => console.warn(x.propertyName + ':' + x.errorMessage));
    }
  } else if (err.status === 401 ) {

    const message = '...niste ovlašteni za ovu operaciju.';
    channel.publish('ajaxerror.add', {
      message: message
    });
  } else {
    channel.publish('ajaxerror.add', {
      message: serverCommunicationError
    });
  }
};

function performRequestWithToken(req, idToken, cb) {
  channel.publish('ajax.begin', {});

  req
    .set('Authorization', 'Bearer ' + idToken)
    .end((reqErr, res) => {
      channel.publish('ajax.end', {});

      if(reqErr) {
        handleError(reqErr, res);
        return;
      }

      if (req.method === 'POST' || req.method === 'PUT') {
        channel.publish('ajax.save', {});
      }
      if (req.method === 'DELETE') {
        channel.publish('ajax.delete', {});
      }

      cb(res.body);
    });
}

function performRequest(req, cb) {
  const accessToken = auth0.getAccessToken();

  if (accessToken) {
    performRequestWithToken(req, accessToken, cb);
  } else {
    auth0.login();
  }
}

var ajax = {
  notFound: 'Nije pronađeno!',
  serverCommunicationError: '...pri komunikaciji s poslužiteljem',

  notFoundCallback: function () {},

  notFoundMessage: function (newNotFoundMessage) {
    this.notFound = newNotFoundMessage;
    return this;
  },

  onNotFound: function (newCb) {
    this.notFoundCallback = newCb;
    return this;
  },

  get: function () {

    var url = arguments[0];
    let cb = null;
    if (arguments.length === 3) {
      var query = arguments[1];
      cb = arguments[2];
    } else {
      cb = arguments[1];
    }
    var req = request.get(url);
    if (query) {
      req = req.query(query);
    }
    performRequest(req, cb);
  },

  post: function (url, data, cb) {
    let req = request
      .post(url)
      .send(data)
      .set('Accept', 'application/json');

    performRequest(req, cb);
  },

  put: function (url, data, cb) {
    let req = request
      .put(url)
      .send(data)
      .set('Accept', 'application/json');

    performRequest(req, cb);
  },

  del: function (url, cb) {
    let req = request
      .del(url)
      .set('Accept', 'application/json');

    performRequest(req, cb);
  },
  upload: function (url, files, cb) {

    let req = request
      .post(url)
      .set('Accept', 'application/json');

    files.forEach(file => {
      req.attach('files', file);
    });

    performRequest(req, cb);
  }
};

export default ajax;
