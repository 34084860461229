import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import request from '../helpers/request';

import reportConfig from './config/reportConfig';

class GdprConsentViewReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gdprConsent: null
    };
  }
  componentDidMount() {
    request
    .get(`${reportConfig.apiUrl}GetDetails/${this.props.match.params.id}`, gdprConsent => {
      this.setState({
        gdprConsent,
      }, () => window.print());
    });
  }
  render() {
    const centerStyle = {
      textAlign: 'center'
    };

    const consent = this.state.gdprConsent ? this.state.gdprConsent.consent : '';
    const consentDate = this.state.gdprConsent
      ? moment(this.state.gdprConsent.date).format('Do MMMM YYYY')
      : '';

    return (
      <div>
        <div>
          Schanzer posao d.o.o.<br />
          Jurišićeva ul. 19/1<br />
          HR-10000 Zagreb <br />
          OIB: 32266907102
        </div>
        <div style={{float: 'right'}}>
          Zagreb, {moment().format('Do MMMM YYYY')}
        </div>
        <div style={{clear: 'both'}}></div>
        <br />
        <br />
        <div>
          <h1 style={centerStyle}>EVIDENCIJA OSOBNIH PODATAKA</h1>
          <h2 style={centerStyle}>Uvid u obradu podataka</h2>
        </div>
        <br />
        <br />
        <br />
        <hr />
        <strong>Obrađivani podaci:</strong>
        <br />
        {consent}
        <br />
        <br />
        <strong>Datum privole:</strong>
        <br />
        {consentDate}
        <br />
        <br />
        <hr />
        <p>
        Schanzer Posao na temelju vaše privole, obrađuje gore navedene podatke, sukladno pravilima o zaštiti osobnih podataka.
        </p>
        <p>
        U svakom trenutku možete opozvati svoju privolu ispunjavanjem zahtjeva koji možete dobiti na web stranici www.schanzer-posao.com ili upitom na info@schanzer-posao.com
        </p>
      </div>
    );
  }
}

export default withRouter(GdprConsentViewReport);
