import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Radno vrijeme - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetJobWorkInfoTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobWorkInfoTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobWorkInfoTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobWorkInfoTemplate`,
};

const JobWorkInfos = () => <JobTemplateView {...templateProps} />;

export default JobWorkInfos;
