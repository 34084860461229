import React from 'react';

import JobTemplateView from '../common/JobTemplateView';

import templateConfig from './config/templateConfig';

const templateProps = {
  title: 'Preduvjeti - Predlošci',
  getApiUrl: `${templateConfig.apiUrl}GetJobRequirementTemplates`,
  updateApiUrl: `${templateConfig.apiUrl}EditJobRequirementTemplate`,
  deleteApiUrl: `${templateConfig.apiUrl}DeleteJobRequirementTemplate`,
  newApiUrl: `${templateConfig.apiUrl}CreateJobRequirementTemplate`,
};

const JobRequirements = () => <JobTemplateView {...templateProps} />;

export default JobRequirements;
