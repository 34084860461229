import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import request from '../../helpers/request';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CustomButton from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PrintIcon from '@material-ui/icons/Print';

import GdprConsentTable from './GdprConsentTable';

import gdprConfig from './config/gdprConfig';

class GdprConsentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gdprConsents: [],
      term: '',
      page: 1,
      pageSize: 25,
      totalCount: 0,
      pageCount: 0,
      detailsSelected: false,
      deleteSelected: false,
      deletedGdprConsent: null,
      selectedGdprConsent: null,
      showGdprRevokeDialog: false,
      revokeName: ''
    };

    this.refreshData = this.refreshData.bind(this);
    this.applyTermFilter = this.applyTermFilter.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.changePage = this.changePage.bind(this);

    this.onGdprConsentDeleted = this.onGdprConsentDeleted.bind(this);
    this.onGdprConsentSelected = this.onGdprConsentSelected.bind(this);

    this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
    this.confirmConsentChange = this.confirmConsentChange.bind(this);

    this.confirmDelete = this.confirmDelete.bind(this);
  }
  changeRowsPerPage(pageSize) {
    this.setState({
      pageSize
    }, () => this.refreshData());
  }
  applyTermFilter(term) {
    this.setState({
      term
    }, () => this.refreshData());
  }
  changePage(selectedPage) {
    if (selectedPage === this.state.page) {
      return;
    }

    this.setState({page: selectedPage}, () => this.refreshData());
  }
  getQueryParams() {
    return {
      term: this.state.term,
      published: this.state.published,
      page: this.state.page,
      pageSize: this.state.pageSize
    };
  }
  onGdprConsentDeleted(deletedGdprConsent) {
    this.setState({
      deleteSelected: true,
      deletedGdprConsent: {...deletedGdprConsent}
    });
  }
  onGdprConsentSelected(selectedGdprConsent) {
    this.setState({
      detailsSelected: true,
      selectedGdprConsent: {...selectedGdprConsent}
    });
  }
  confirmConsentChange() {
    request.put(
      `${gdprConfig.apiUrl}Update`,
      this.state.selectedGdprConsent,
      result => {
        const gdprConsents = this.state.gdprConsents;

        const updateIndex = gdprConsents.findIndex(x => x.id === result.id);
        if (updateIndex >= 0) {
          gdprConsents[updateIndex] = result;
          this.setState({
            gdprConsents,
            selectedGdprConsent: null,
            detailsSelected: false
          });
        }
      }
    );
  }
  confirmDelete() {
    request.del(
      `${gdprConfig.apiUrl}Delete/${this.state.deletedGdprConsent.id}`,
      () => {
        const gdprConsents = this.state.gdprConsents;

        const deleteIndex = gdprConsents.findIndex(x => x.id === this.state.deletedGdprConsent.id);
        if (deleteIndex >= 0) {
          gdprConsents.splice(deleteIndex, 1);
          this.setState({
            gdprConsents,
            deletedGdprConsent: null,
            deleteSelected: false
          });
        }
      }
    );
  }
  refreshData() {
    request
      .get(`${gdprConfig.apiUrl}GetAll`, this.getQueryParams(), response => {
        this.setState({
          gdprConsents: response.data,
          totalCount: response.count,
          pageCount: response.pageCount
        });
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  render() {
    return (
      <div>
        <Tooltip
          title="Kreiraj dokument za opoziv"
          placement="top"
        >
          <CustomButton
            color="warning"
            justIcon
            onClick={() => this.setState({showGdprRevokeDialog: true})}
          >
            <PrintIcon />
          </CustomButton>
        </Tooltip>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GdprConsentTable
              page={this.state.page - 1}
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              items={this.state.gdprConsents}
              onItemSelected={this.onGdprConsentSelected}
              onItemDeleted={this.onGdprConsentDeleted}
              onChangeRowsPerPage={this.changeRowsPerPage}
              onChangePage={this.changePage}
              onTermFilterChanged={this.applyTermFilter}
            />
          </GridItem>
        </GridContainer>
        {this.state.detailsSelected &&
          <Dialog
            open={this.state.detailsSelected}
            onClose={() => this.setState({detailsSelected: false, selectedGdprConsent: null})}
            aria-labelledby="model-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="modal-dialog-title">Detalji privole</DialogTitle>
            <form noValidate autoComplete="off">
              <TextField
                error={!this.state.selectedGdprConsent.consent}
                style={{
                  marginLeft: '15px',
                  marginRight: '15px',
                  width: '95%'
                }}
                label="Privola"
                variant="outlined"
                value={this.state.selectedGdprConsent.consent}
                onChange={e => {
                  const state = {...this.state};
                  state.selectedGdprConsent.consent = e.target.value;
                  this.setState(state);
                }}
              />
            </form>
            <DialogActions>
              <Button color="primary"
                onClick={() => this.setState({detailsSelected: false, selectedGdprConsent: null})}
              >
                Odustani
              </Button>
              <Button
                color="primary"
                justIcon
                component={Link}
                target="_blank"
                to={`/report/consentview/${this.state.selectedGdprConsent.id}`}
              >
                Uvid
              </Button>
              <Button color="primary"
                disabled={!this.state.selectedGdprConsent.consent}
                onClick={this.confirmConsentChange}
              >
                Izmjeni
              </Button>
            </DialogActions>
          </Dialog>
        }
        {this.state.deleteSelected &&
          <Dialog
            open={this.state.deleteSelected}
            onClose={() => this.setState({deleteSelected: false, deletedGdprConsent: null})}
            aria-labelledby="model-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="modal-dialog-title">Da li ste sigurni da želite obrisati privolu</DialogTitle>
            <form noValidate autoComplete="off">
              <TextField
                style={{
                  marginLeft: '15px',
                  marginRight: '15px',
                  width: '95%'
                }}
                label="Privola"
                variant="outlined"
                value={this.state.deletedGdprConsent.consent}
                onChange={() => {}}
              />
            </form>
            <DialogActions>
              <Button color="primary"
                onClick={() => this.setState({deleteSelected: false, deletedGdprConsent: null})}
              >
                Odustani
              </Button>
              <Button
                onClick={this.confirmDelete}
                color="primary"
              >
                Obriši
              </Button>
            </DialogActions>
          </Dialog>
        }
        {this.state.showGdprRevokeDialog &&
          <Dialog
            open={this.state.showGdprRevokeDialog}
            onClose={() => this.setState({showGdprRevokeDialog: false, revokeName: ''})}
            aria-labelledby="model-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="modal-dialog-title">Kreiraj dokument za opoziv</DialogTitle>
            <form noValidate autoComplete="off">
              <TextField
                style={{
                  marginLeft: '15px',
                  marginRight: '15px',
                  width: '95%'
                }}
                label="Ime i prezime"
                variant="outlined"
                value={this.state.revokeName}
                onChange={e => this.setState({revokeName: e.target.value})}
              />
            </form>
            <DialogActions>
              <Button color="primary"
                onClick={() => this.setState({showGdprRevokeDialog: false, revokeName: ''})}
              >
                Odustani
              </Button>
              <Button
                color="primary"
                component={Link}
                target="_blank"
                to={`/report/consentrevoke/${this.state.revokeName}`}
              >
                Kreiraj
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
}

export default withRouter(withStyles(regularFormsStyle)(GdprConsentList));
