import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  openLightbox(index, event) {
    event.preventDefault();
    this.setState({ currentImage: index, lightboxIsOpen: true });
  }
  closeLightbox() {
    this.setState({ currentImage: 0, lightboxIsOpen: false });
  }
  gotoPrevious() {
    this.setState({ currentImage: this.state.currentImage - 1 });
  }
  gotoNext() {
    this.setState({ currentImage: this.state.currentImage + 1 });
  }
  gotoImage(index) {
    this.setState({ currentImage: index });
  }
  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) {
      return;
    }

    this.gotoNext();
  }
  handleDelete(index) {
    if (this.props.onDelete) {
      this.props.onDelete(index);
    }
  }
  render() {
    const images = this.props.images;

    if (!images) {
      return null;
    }

    const thumbnails = images.map((i, index) =>
      <div
        key={index}
        style={{
          display: 'inline-block',
          maxHeight:'250px',
          height: '250px',
          position: 'relative',
          padding: '25px'
        }}>
        <a
          href={i.src}
          onClick={e => this.openLightbox(index, e)}
        >
          <img
            alt=""
            src={i.thumbnail}
            style={{
              maxWidth: '250px',
              width: 'auto',
              height: 'auto'
            }}
          />
        </a>
        <Button
          style={{
            position: 'absolute',
            top: -5,
            left: -5
          }}
          variant="fab"
          color="secondary"
          aria-label="Obriši"
          onClick={() => this.handleDelete(index)}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
    return (
      <div>
        {thumbnails}
        <Lightbox
          currentImage={this.state.currentImage}
          images={this.props.images}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          showThumbnails={true}
        />
      </div>
    );
  }
}

ImageGallery.propTypes = {
  images: PropTypes.array,
  onDelete: PropTypes.func
};

export default ImageGallery;
