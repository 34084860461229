import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class ModalDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  open() {
    this.setState({isOpen: true});
  }
  close() {
    this.setState({isOpen: false},
      () => {
        if (!this.props.onClose) {
          this.props.onClose();
        }
      });
  }
  render() {
    return (
      <Dialog
        open={this.state.isOpen}
        onClose={this.handleClose}
        aria-labelledby="model-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="modal-dialog-title">{this.props.title}</DialogTitle>
        {this.props.children}
        <DialogActions>
          <Button color="primary" onClick={() => this.close()}>
            Zatvori
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func
};

export default ModalDialog;
