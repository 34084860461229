import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ReportMain extends Component {
  componentWillMount() {
    document.body.style = 'background: white;';
    // document.body.onafterprint = function () {
    //   window.close();
    // };
  }
  render() {
    const reportStyle = {
      maxWidth: '1024px',
      backgroundColor: 'white',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '20px'
    };

    return (
      <div style={reportStyle}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(ReportMain);
